import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { AuthProvider } from './AuthContext'; // Import the AuthProvider
import RequireAuth from './RequireAuth';
import Login from './login';
import Subscribe from './Subscribe';
import TermsAndConditions from './TermsAndConditions';
import Tool from './Tool';
import BlenderAuth from './BlenderAuth';
import PluginAuth from './PluginAuth';
import PluginAuthSuccess from './PluginAuthSuccess';
import './App.css';

function App() {
  return (
    <Router>
      <AuthProvider> {/* Context provider to handle authentication state */}
        <Routes>
          <Route path="/" element={
            <RequireAuth>
              <Tool /> {/* Tool is protected by RequireAuth and should only be accessible if logged in and subscribed */}
            </RequireAuth>
          } />
          <Route path="/login" element={<Login />} />
          <Route path="/subscribe" element={<Subscribe />} />
          <Route path="/terms-of-service" element={<TermsAndConditions />} />
          <Route path="/plugin-auth" element={<PluginAuth />} />
          <Route path="/plugin-auth-success" element={<PluginAuthSuccess />} />
          <Route path="/blender-auth" element={<BlenderAuth />} />

          {/* Other routes can go here */}
        </Routes>
      </AuthProvider>
    </Router>
  );
};

export default App;




