import React,  { useState } from 'react';

import './Preferences.css'; // Import common styles
import Tooltip from './Tooltip';


const Preferences = ({ closePreferences, contextAwareGeneration, setContextAwareGeneration, modelVersion, setModelVersion }) => {

    const contextAware = [
        { id: '1', name: 'On', value: true },
        { id: '2', name: 'Off', value: false },
    ];
    const modelVersions = [
        { id: '1', name: '0.6.0', value: '0.6.0' },
        { id: '2', name: '0.5.0', value: '0.5.0' },
        { id: '3', name: '0.4.0', value: '0.4.0' },
        { id: '4', name: '0.3.1', value: '0.3.1' },
    ];
      
    const [activeContextButton, setActiveContextButton] = useState(
        contextAware.find(button => button.value === contextAwareGeneration)
    );
    const [activeModelButton, setActiveModelButton] = useState(
        modelVersions.find(button => button.value === modelVersion)
    );
    
    const toggleContextActive = (id) => {
        const newActiveButton = contextAware.find(button => button.id === id);
        setActiveContextButton(newActiveButton);
        setContextAwareGeneration(newActiveButton.value);
    };
    const toggleModelActive = (id) => {
        const newActiveButton = modelVersions.find(button => button.id === id);
        setActiveModelButton(newActiveButton);
        setModelVersion(newActiveButton.value);
    };

    const isContextActive = (button) => button.id === activeContextButton.id;
    const isModelActive = (button) => button.id === activeModelButton.id;



  return (

    <div className="overlay">

      <div className="preferencesMenu">

        <div className="trainerTitleContainer">

          <span style={{ fontSize: '16px', marginLeft: '25px' }} className='boldText'>Preferences</span>

          <button className="closeButton" onClick={closePreferences}>

            <div className="closeIcon" style={{ marginLeft: '2px' }}></div>

          </button>

        </div>

        <div style={{ display: 'inline-block', marginTop: '1px' }} className="horizontal-line-trainer"></div>

        <div style={{ padding: '20px', textAlign: 'center', color: '#808080' }}>

                <div className="toggleSettingContainer" style={{ justifyContent: 'space-between' }}>
                    <Tooltip message="Context Aware Generation greatly improves the generation quality and flexibility of the AI model by allowing it analysize the input image to gain context for the generation. If you prefer the software not to analyze the image (this is completely anonymous and no data is stored), you can turn this feature off at the cost of generation quality."
                    position="right"
                    style={{ display: 'inline-block', fontSize: '12px', marginLeft: '10px', color: '#808080' }} 
                    className='normalText'>
                    Context Aware Generation
                    </Tooltip>

                    <div className="toggle-container">
                    {contextAware.map((button) => (
                        <button
                        key={button.id}
                        className={`toggle-btn${isContextActive(button) ? ' active' : ''}`}
                        style={{ fontSize: '10px'}}
                        onClick={() => toggleContextActive(button.id)}
                        >
                        {button.name}
                        </button>
                    ))}

                    </div>
                </div>

                <div className="toggleSettingContainer" style={{ justifyContent: 'space-between', marginTop: '0px' }}>
                    <Tooltip message="Select the version of the model to use."
                    position="right"
                    style={{ display: 'inline-block', fontSize: '12px', marginLeft: '10px', color: '#808080' }}
                    className='normalText'>
                    Model Version
                    </Tooltip>
                    <div className="toggle-container">
                    {modelVersions.map((button) => (
                        <button
                        key={button.id}
                        className={`toggle-btn${isModelActive(button) ? ' active' : ''}`}
                        style={{ fontSize: '10px'}}
                        onClick={() => toggleModelActive(button.id)}
                        >
                        {button.name}
                        </button>
                    ))}
                    </div>
                </div>
            </div>
        </div>
    </div>
    );
};



export default Preferences;